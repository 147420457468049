import { useState } from 'react'
import axios from 'axios'

export function useWorker () {
  const [outcome, setOutcome] = useState(null)
  const [error, setError] = useState(null)
  const [working, setWorking] = useState(null)

  const build = async (options) => {
    try {
      setWorking(true)
      const response = await axios.post('/worker/build', options)
      setOutcome(response.data)
      return response.data
    } catch (error) {
      setError(error)
    } finally {
      setWorking(false)
    }
  }

  const run = async (options) => {
    try {
      setWorking(true)
      const response = await axios.post('/worker/run', options)
      setOutcome(response.data)
      return response.data
    } catch (error) {
      // todo Full error handling as described here https://axios-http.com/docs/handling_errors
      setError(error)
    } finally {
      setWorking(false)
    }
  }

  return { build, run, outcome, error, working }
}
