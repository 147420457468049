import { create } from 'zustand'
import { nanoid } from 'nanoid'

const urlParams = new URLSearchParams(window.location.search)

function generateProjectName () {
  const projectName = nanoid()
  window.sessionStorage.setItem('projectName', projectName)
  return projectName
}

export const useGlobal = create(() => ({
  projectName: window.sessionStorage.getItem('projectName') ?? generateProjectName(),
  isEmbedded: urlParams.get('embed') ?? false
}))
