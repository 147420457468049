import { Popover, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'

import { Select } from '../../Select/index.js'
import { Input } from '../../Input/index.js'
import { useSettings } from '../../../hooks/index.js'

export default function Settings () {
  const settings = useSettings()

  return (
    <Popover className='relative'>
      {() => (
        <>
          <Popover.Button
            className='p-1 text-gray-400 hover:text-gray-50 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 rounded-md transition-colors'
          >
            <EllipsisVerticalIcon className='w-6 h-6 shrink-0' />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel className='absolute left-0 mt-1 z-10 w-screen max-w-sm transform px-4 sm:px-0 lg:max-w-3xl'>
              <div className='rounded-sm shadow-lg ring-1 ring-gray-700'>
                <div className='flex flex-col space-y-4 bg-gray-800 px-4 py-2'>
                  <Select
                    label='Compiler version'
                    options={settings.compilersList}
                    selected={settings.compilerVersion}
                    onSelect={(option) => settings.setCompilerVersion(option)}
                  />
                  <Input
                    name='compiler-options'
                    label='Compiler options'
                    value={settings.compilerOptions}
                    className='font-mono'
                    onChange={(e) => {
                      settings.setCompilerOptions(e.currentTarget.value)
                    }}
                  />
                  <Input
                    name='program-options'
                    label='Program options'
                    value={settings.programOptions}
                    className='font-mono'
                    onChange={(e) => {
                      settings.setProgramOptions(e.currentTarget.value)
                    }}
                  />
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
