export function Loader ({ text, className }) {
  return (
    <div className={className}>
      {text}
      <span className='loader-dot-1'>.</span>
      <span className='loader-dot-2'>.</span>
      <span className='loader-dot-3'>.</span>
    </div>
  )
}

Loader.defaultProps = {
  text: 'Loading'
}
