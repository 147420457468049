import { create } from 'zustand'

export const useSettings = create((set) => ({
  programmingLang: 'C++',
  compilerVersion: { name: 'gcc', desc: '13.2.0' },
  compilersList: [
    { name: 'gcc', desc: '13.2.0' },
    { name: 'gcc', desc: '12.3.0' },
    { name: 'gcc', desc: '11.4.0' },
    { name: 'gcc', desc: '10.5.0' },
    { name: 'gcc', desc: '9.5.0' }
  ],
  compilerOptions: '-std=c++20 -O2 -Wall -pedantic',
  programOptions: '',
  setProgrammingLang: (programmingLang) => set(() => ({ programmingLang })),
  setCompilerVersion: (compilerVersion) => set(() => ({ compilerVersion })),
  setCompilerOptions: (compilerOptions) => set(() => ({ compilerOptions })),
  setProgramOptions: (programOptions) => set(() => ({ programOptions }))
}))
