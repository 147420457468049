import SpaceTwo from '../../../images/spacetwo.svg'
import { useEditor, useGlobal } from '../../../hooks/index.js'
import Button from './Button.js'

export default function OpenEditor () {
  const editor = useEditor()
  const global = useGlobal()

  const handleClick = () => {
    const url = (() => {
      if (global.isEmbedded) {
        return `https://spacetwo.dev/?embed=0&code=${encodeURIComponent(editor.sourceCode)}`
      }
      return 'https://spacetwo.dev'
    })()

    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <Button onClick={handleClick}>
      <img src={SpaceTwo} alt='' className='p-0.5 h-6' aria-hidden='true' />
    </Button>
  )
}
