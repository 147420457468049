import { Fragment } from 'react'

import { Tab } from '@headlessui/react'
import ReactCodeMirror from '@uiw/react-codemirror'
import { editorTheme } from './editorTheme.js'
import { cpp } from '@codemirror/lang-cpp'

import { useEditor } from '../../hooks'

export function Editor () {
  const state = useEditor((state) => state)

  const handleEditorChange = (sourceCode) => {
    state.setSourceCode(sourceCode)
  }

  return (
    <div
      id='Editor'
      className='flex flex-col'
    >
      <Tab.Group
        defaultIndex={0}
      >
        <Tab.List
          className='border-b-2 border-b-gray-800'
        >
          <Tab as={Fragment}>
            {() => (
              <button
                type='button'
                className='px-3 py-2 text-sm text-gray-200 border-b-[3px] border-b-emerald-500'
              >
                main.cpp
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels
          className='flex-1'
        >
          <Tab.Panel className='relative h-full'>
            <ReactCodeMirror
              value={state.sourceCode}
              theme={editorTheme}
              height='100%'
              extensions={[cpp()]}
              onChange={handleEditorChange}
              className='absolute inset-0 text-sm'
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
