import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

export function Select ({ label, options, selected, onSelect }) {
  return (
    <div className='flex flex-col space-y-2'>
      <label className='text-sm text-white font-bold'>
        {label}
      </label>
      <Listbox value={selected} onChange={onSelect}>
        <div className='relative w-full'>
          <Listbox.Button className='relative w-full cursor-default rounded-sm ring-1 ring-gray-700 text-white bg-transparent hover:bg-gray-700 py-1.5 pl-2 pr-8 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
            <span className='block truncate'>{selected.name} <span className='text-gray-300 italic'>{selected.desc}</span></span>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronDownIcon
                className='h-4 w-4 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute w-full mt-1 max-h-60 overflow-auto rounded-sm bg-gray-800 py-1 text-sm shadow-lg ring-1 ring-gray-700 focus:outline-none'>
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-1.5 pl-8 pr-3 text-sm ${
                      active ? 'bg-gray-700 text-white' : 'text-gray-50'
                    }`}
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option.name} <span className='text-gray-300 italic'>{option.desc}</span>
                      </span>
                      {selected
                        ? (
                          <span className='absolute inset-y-0 left-0 flex items-center pl-2 text-gray-400'>
                            <CheckIcon className='h-4 w-4' aria-hidden='true' />
                          </span>
                          )
                        : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
