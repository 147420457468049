import { tags } from '@lezer/highlight'
import { createTheme } from '@uiw/codemirror-themes'

const uno = [
  '#d6e9ff',
  '#88b4e7',
  '#5d8cc0',
  '#586f89',
  '#444c55'
]

const duo = [
  '#34febb',
  '#32ae85',
  '#42675a'
]

const editorTheme = createTheme({
  dark: 'dark',
  settings: {
    background: '#111827',
    backgroundImage: '',
    foreground: uno[0],
    caret: '#AEAFAD',
    selection: '#D6D6D6',
    selectionMatch: '#D6D6D6',
    gutterBackground: '#111827',
    gutterForeground: '#6b7280',
    gutterBorder: '#111827',
    gutterActiveForeground: '',
    lineHighlight: '#1e293b'
  },
  styles: [
    { tag: tags.comment, color: uno[3] },
    { tag: tags.typeName, color: duo[0] },
    { tag: tags.variableName, color: uno[2] },
    { tag: tags.function(tags.variableName), color: uno[0] },
    { tag: tags.operator, color: duo[1] },
    { tag: tags.literal, color: duo[0] },
    { tag: tags.keyword, color: duo[0] },
    { tag: tags.controlKeyword, color: duo[1] },
    { tag: tags.bracket, color: uno[3] },
    { tag: tags.processingInstruction, color: duo[1] },
    { tag: tags.namespace, color: uno[1] }
  ]
})

export { editorTheme }
