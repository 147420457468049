import { useRef, useEffect } from 'react'

import { useOutput } from '../../hooks/index.js'

export function Output () {
  const output = useOutput()
  const outputRef = useRef(null)

  useEffect(() => {
    outputRef.current.scrollTop = outputRef.current.scrollHeight
  }, [output.text])

  return (
    <div
      id='Output'
      className='flex-1 relative flex flex-col text-sm text-gray-300 font-mono'
    >
      <label
        htmlFor='output'
        className='hidden'
      >
        Output
      </label>
      <textarea
        id='output'
        value={output.text}
        readOnly
        className='flex-1 m-0 border-0 focus:outline-none focus:ring-0 px-3 py-2 bg-gray-900 text-sm text-gray-300 font-mono resize-none'
        ref={outputRef}
      />
    </div>
  )
}
