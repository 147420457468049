import BuildAndRun from './components/BuildAndRun.js'
import Settings from './components/Settings.js'
import ShowHelp from './components/ShowHelp.js'
import OpenEditor from './components/OpenEditor.js'

const topButtons = [
  { name: 'buildAndRun', component: BuildAndRun },
  { name: 'settings', component: Settings }
]

const bottomButtons = [
  { name: 'showHelp', component: ShowHelp },
  { name: 'openEditor', component: OpenEditor }
]

export function Sidebar () {
  return (
    <div className='relative z-10 flex flex-col justify-between p-1 bg-gray-900 border-r-2 border-r-gray-800'>

      {/* Gradient overlay */}
      <div className='absolute -z-10 inset-0 bg-gradient-to-b from-gray-800 from-75% to-emerald-500 opacity-10' />

      {/* Buttons top */}
      <ul role='list' className='flex flex-col justify-center space-y-2'>
        {topButtons.map((btn) => (
          <li key={btn.name} className='flex justify-center items-center'>
            <btn.component />
          </li>
        ))}
      </ul>

      {/* Buttons bottom */}
      <ul role='list' className='flex flex-col justify-center space-y-2'>
        {bottomButtons.map((btn) => (
          <li key={btn.name} className='flex justify-center items-center'>
            <btn.component />
          </li>
        ))}
      </ul>
    </div>
  )
}
