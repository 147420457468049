import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

import Button from './Button.js'

const globalHotKeys = [
  { name: 'buildAndRun', desc: 'Build and run', keys: ['Ctrl', 'B'] }
]

export default function ShowHelp () {
  const [isOpen, setIsOpen] = useState(false)

  function closeModal () {
    setIsOpen(false)
  }

  function openModal () {
    setIsOpen(true)
  }

  return (
    <>
      <Button
        onClick={openModal}
      >
        <QuestionMarkCircleIcon className='w-6 h-6' />
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className='w-full max-w-md transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle shadow-xl transition-all'
                >
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-50'
                  >
                    Help
                  </Dialog.Title>
                  <div className='mt-6'>
                    <h4 className='text-base font-medium text-gray-50'>
                      Global hot keys
                    </h4>
                    <p className='mt-2 text-sm text-gray-400'>
                      Global hot keys are keyboard shortcuts available inside and outside of the code editor.
                    </p>
                    <ul role='list' className='mt-4'>
                      {globalHotKeys.map((hotKey) => (
                        <li key={hotKey.name} className='flex justify-between gap-x-6 py-1'>
                          <p className='text-sm leading-6 text-gray-50 font-medium'>
                            {hotKey.desc}
                          </p>
                          <li className='flex items-center'>
                            {hotKey.keys.map((key, index) => (
                              <div key={key} className='inline-flex items-center'>
                                <span
                                  className='inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20'
                                >
                                  {key}
                                </span>
                                {index < hotKey.keys.length - 1 && (
                                  <span
                                    className='inline-flex items-center rounded-md bg-transparent -mt-1 px-2 py-1 text-base font-medium text-gray-400'
                                  >
                                    +
                                  </span>
                                )}
                              </div>
                            ))}
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className='mt-6'>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-md border border-transparent bg-emerald-100 px-4 py-2 text-sm font-medium text-emerald-900 hover:bg-emerald-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-emerald-500 focus-visible:ring-offset-2'
                      onClick={closeModal}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
