import React from 'react'
import Split from 'react-split'

import { Sidebar, Editor, Output } from './components'

export function App () {
  return (
    <div className='h-full relative flex flex-row'>
      <Sidebar />
      <Split
        id='Split'
        sizes={[70, 30]}
        direction='vertical'
        className='flex-1'
      >
        <Editor />
        <Output />
      </Split>
    </div>
  )
}
