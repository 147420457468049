import { PlayIcon } from '@heroicons/react/24/outline'

import { useEditor, useGlobal, useOutput, useSettings, useWorker } from '../../../hooks'
import Button from './Button.js'
import { useHotkeys } from 'react-hotkeys-hook'

export default function BuildAndRun () {
  useHotkeys('ctrl+b', () => handleClick(), { preventDefault: true, enableOnFormTags: true, enableOnContentEditable: true })
  const global = useGlobal()
  const editor = useEditor()
  const settings = useSettings()
  const output = useOutput()
  const worker = useWorker()

  function handleClick () {
    output.clear()

    const buildOptions = {
      projectName: global.projectName,
      compilerVersion: `${settings.compilerVersion.name}:${settings.compilerVersion.desc}`,
      compilerOptions: settings.compilerOptions,
      sourceFile: 'main.cpp',
      sourceCode: editor.sourceCode,
      outputFile: 'main.out'
    }

    output.add(`[${buildOptions.compilerVersion}] g++ ${buildOptions.compilerOptions} ${buildOptions.sourceFile} -o ${buildOptions.outputFile}`)

    // Build
    worker.build(buildOptions)
      .then((buildResult) => {
        output.add(buildResult.buildOutput)

        if (buildResult.buildError) {
          return
        }

        const runOptions = {
          projectName: global.projectName,
          compilerVersion: `${settings.compilerVersion.name}:${settings.compilerVersion.desc}`,
          executableFile: 'main.out',
          programOptions: settings.programOptions
        }

        output.add(`[${runOptions.compilerVersion}] ${runOptions.executableFile} ${runOptions.programOptions}`)

        // Run
        worker.run(runOptions)
          .then((runResult) => {
            output.add(runResult.runOutput)
          })
      })
  }

  return (
    <Button onClick={handleClick}>
      <PlayIcon className='h-6 w-6 shrink-0' aria-hidden='true' />
    </Button>
  )
}
