export function Input ({ name, label, value, className, ...props }) {
  return (
    <div className='flex flex-col space-y-2'>
      <label
        htmlFor='name'
        className='text-sm text-white font-bold'
      >
        {label}
      </label>
      <input
        id={name}
        name={name}
        value={value}
        className={`${className} bg-transparent rounded-sm ring-1 ring-inset ring-gray-700 text-sm px-2 py-1.5 text-white focus:ring-2 focus:ring-inset focus:ring-emerald-500 focus:outline-none`}
        {...props}
      />
    </div>
  )
}
